<template>
  <div class="stack-context-menu">
    <!--    <template v-if="isAvailablePorts.length && tariff.name !== 'Anti-DDOS'">-->
    <!--      <div class="stack-context-menu__noact standart-text">-->
    <!--        <div>{{ $t('noact.noactions') }}</div>-->
    <!--      </div>-->
    <!--    </template>-->
    <template>
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import deleteAntiddos from '@/layouts/Stack/mixins/deleteAntiddos';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'PublicSubnetContextMenu',
  mixins: [deleteAntiddos, showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(
            x =>
              x.intname ===
              `network_${
                this.tariff.cidr.split('/')[1] === '24' ? '32' : this.tariff.cidr.split('/')[1]
              }`
          )[this.dev]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    isAvailablePorts() {
      // console.log(this.$store.state.moduleStack.ports.map(x => x.fixed_ips[0]));
      return this.$store.state.moduleStack.ports.filter(x => {
        return x.fixed_ips[0] ? x.fixed_ips[0].subnet_id === this.tariff.id : null;
      });
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    networkId() {
      return this.tariff.id;
    },
    id() {
      return this.$store.getters['moduleStack/GET_CURRENT'];
    },
    processingStatus() {
      // return this.tariff.specialstatus.toNumber();
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const arr = [];
      this.tariff.name === 'Anti-DDOS'
        ? arr.push([
            {
              key: 'anti',
              color: 'del',
              // handler: 'delete',
              action: 'anti',
            },
          ])
        : arr.push([
            {
              key: 'delete',
              color: 'del',
              handler: 'delete',
              action: 'delete',
            },
          ]);
      return arr;
    },
  },
  mounted() {
    // if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'state') this.askSure(item);
      if (item.action && item.handler === 'delete') {
        if (!this.isAvailablePorts.length) {
          this.deleteSubnet(item);
        } else {
          this.showError('Для удаления подсети сначала удалите все порты.');
        }
      }
      if (item.action && item.key === 'anti') this.deleteAntiddos(this.tariff);
    },
    changeAdminState(item) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateNetwork', {
        network: item.action,
        id: item.id,
        item: 'admin_state_up',
      });
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action.label}`) }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.changeAdminState(action)
                  .then(data => {
                    this.isProcessing = false;
                    mode = data;
                    res = 'success';
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`, {
                        msg: this.$t(`modal.sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteSubnet() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        // text: this.$t('delete') + ' ' + this.networkId + '?',
        text: this.$t('delete', {
          name: this.tariff.name,
          id: this.networkId,
          cost: this.$n(this.cost, 'currency', this.$i18n.locale),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentSubnet().then(async data => {
                  if (data) {
                    res = 'success';
                    return res;
                  } else return;
                });
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  // self.text = this.$t(`modal.sure.${res}`);
                  self.text = this.$t('modal.sure.success');
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
                          this.$router.push({ name: 'viewStackPublic' }).catch(() => {});
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteCurrentSubnet() {
      const params = {
        network: this.networkId,
        id: this.id,
      };
      return this.$store.dispatch('moduleStack/deletePublicNetwork', params).catch(e => {
        this.showError(e.response.data.message);
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить подсеть: <b>%{name}</b> (%{id})? <br /> <br /> При этом ваши расходы уменьшатся на <b>%{cost}</b> в час.",
    "actions": {
      "edit": "Изменить шлюз",
      "false": "Выключить",
      "true": "Включить",
      "anti": "Удалить IP-адрес",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить подсеть"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "есть используемые порты",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить",
      "success": "Публичная сеть успешно удалена."
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сеть. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "success": "Публичная сеть успешно удалена.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
