<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title-with-search
          :title="$t('title.server')"
          :search="true"
          class="stack-info__title"
        >
          {{ $t('title.server') }}
          <template #search>
            <tags-filter
              :search="search"
              search-class="max"
              @search-server="searchNetwork($event)"
              class="search"
            ></tags-filter
          ></template>
        </page-title-with-search>
      </div>
      <base-empty v-if="list.length === 0" title="Новая приватная сеть" class="cloud-info__empty">
        <template #link
          ><base-button>
            {{ $t('newServer') }}
          </base-button></template
        >
      </base-empty>
      <tariffs-table-network-public
        :search="search"
        :search-filter="searchFilter"
        :table-head="tableHead"
        :type="true"
      />
    </page-block>
  </div>
</template>

<script>
import TariffsTableNetworkPublic from '../../Main/components/TariffsTableNetworkPublic';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import showError from '@/mixins/showErrorModal';
// import newPublicNetwork from '@/mixins/newPublicNetwork';
import PageTitleWithSearch from '@/components/PageTitle/PageTitleWithSearch.vue';
import TagsFilter from '@/layouts/Stack/components/TagsFilter.vue';
export default {
  name: 'ViewNetworkPublic',
  components: {
    TagsFilter,
    PageTitleWithSearch,
    TariffsTableNetworkPublic,
    BaseEmpty,
  },
  mixins: [storeMixin, showError /*, newPublicNetwork*/],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      search: '',
      searchFilter: '',
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '700px',
            maxWidth: '900px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '14px',
          },
        },
      ],
      isRequest: true,
      isSending: false,
    };
  },
  watch: {
    '$route.query.value': {
      handler: function (val) {
        this.searchFilter = val;
        this.search = val;
      },
      immediate: true,
    },
    searchFilter: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    this.search = '';
    this.setFilter(this.$route.query.value);
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    searchNetwork(event) {
      this.searchFilter = event;
    },
    setFilter(val) {
      this.searchFilter = val;
      this.search = val;
      this.$router
        .push({
          name: 'viewStackPublic',
          query: { filter_by: 'name', value: val },
        })
        .catch(() => {});
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Публичные подсети",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "sure": {
      "confirm": "Создать",
      "title": "Создать новую приватную сеть",
      "text": "Стоимость публичной сети составляет ",
      "cancel": "Отмена"
    },
    "newServer": "Создать подсеть",
    "activeUnder": "Действует до {date}",
    "quotaNetwork": "Вы достигли квоты по количеству публичных сетей, обратитесь в поддержку для изменения квоты",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

      &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        //flex: 1 1 auto;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
