<template>
  <div class="tariffs-table">
    <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
      <tariffs-table-router-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableRouterRow from './TariffsTableRouterRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableRouter',
  components: {
    BaseTable,
    TariffsTableRouterRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            minWidth: '280px',
            maxWidth: '300px',
          },
        },
        {
          key: 'extented',
          label: this.$t('tableHead.extented'),
          style: {
            minWidth: '180px',
            maxWidth: '200px',
          },
        },
        {
          key: 'ip',
          label: this.$t('tableHead.ip'),
          style: {
            minWidth: '140px',
            maxWidth: '160px',
          },
        },
        {
          key: 'state',
          label: this.$t('tableHead.state'),
          style: {
            minWidth: '54px',
            maxWidth: '60px',
            textAlign: 'center',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            minWidth: '54px',
            maxWidth: '64px',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            minWidth: '44px',
            maxWidth: '50px',
          },
        },
      ],
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleStack.routers;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
  },
  methods: {
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "extented": "Внешняя сеть",
      "state": "Состояние",
      "shared": "Доступ",
      "config": "Конфигурация",
      "status": "Статус",
      "ip": "Внешний IP",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
