import DeleteAntiddos from '@/layouts/Stack/components/DeleteAntiddos.vue';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    deleteAntiddos: function (item) {
      const that = this;
      let self;
      return new Promise(() => {
        const selfName = 'DeleteAntiddos';
        const id = item.id;
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: DeleteAntiddos,
          closeOnBackdrop: false,
          props: {
            instance: item,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              // console.log(data);

              item = data;
            },
          },
          // onOpen: inst => (this.modal = inst),
          onOpen(inst) {
            self = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  // self.text = null;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  // console.log(item);
                  this.deleteCurrentIpAnti(item)
                    .then(async data => {
                      // console.log(data);
                      if (data) {
                        this.$modals.close({ name: selfName });
                        this.showResModal('IP-адрес успешно удалён.');
                      } else {
                        this.$modals.close({ name: selfName });
                        this.showResModal('Возможно что-то пошло не так.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    deleteCurrentIpAnti(ip) {
      const params = {
        network: this.networkId,
        id: this.id,
        prefix: ip + '/32',
      };
      // console.log(params);
      return this.$store.dispatch('moduleStack/deletePublicNetwork', params); //.catch(e => {
      // console.log(e);
      // this.showError(e);
      // });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     cancel: {
    //       props: { title: this.$t('close') },
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
